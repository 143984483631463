import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:8000';

const api = axios.create({
  baseURL: API_URL,
  withCredentials: true
});

export const getUserApps = async () => {
  try {
    const response = await api.get('/api/apps/user');
    return response.data;
  } catch (error) {
    console.error('Error fetching apps:', error);
    throw new Error('Failed to load apps');
  }
};

export const getAppData = async (slug) => {
  try {
    const response = await api.get(`/api/apps/${slug}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching app data:', error);
    throw new Error('Failed to load app data');
  }
};
