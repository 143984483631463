import React from 'react';
import { ChevronLeft, Users, LayoutGrid } from 'lucide-react';
import { useNavigate } from 'react-router-dom';

const AdminPanel = () => {
  const navigate = useNavigate();

  const menuItems = [
    {
      title: 'Manage Apps',
      description: 'Create, edit, and manage application settings',
      icon: LayoutGrid,
      path: '/admin/apps',
      color: 'bg-blue-500',
      hoverColor: 'hover:border-blue-500'
    },
    {
      title: 'Manage Users',
      description: 'Manage users and their application access',
      icon: Users,
      path: '/admin/users',
      color: 'bg-blue-500',
      hoverColor: 'hover:border-blue-500'
    }
  ];

  return (
    <div className="min-h-screen bg-white dark:bg-gray-900 text-gray-900 dark:text-white">
      {/* Header */}
      <div className="sticky top-0 z-20 shadow-sm bg-gray-50 dark:bg-gray-800 border-b border-gray-200 dark:border-gray-700">
        <div className="max-w-5xl mx-auto px-6 py-4">
          <div className="flex items-center space-x-4">
            <button
              onClick={() => navigate('/apps')}
              className="flex items-center space-x-2 px-3 py-2 rounded-lg hover:bg-gray-100 dark:hover:bg-gray-700 transition-colors"
            >
              <ChevronLeft className="w-5 h-5" />
              <span>Back to Apps</span>
            </button>
          </div>
        </div>
      </div>

      {/* Main Content */}
      <main className="max-w-5xl mx-auto px-6 py-8">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          {menuItems.map((item) => (
            <button
              key={item.path}
              onClick={() => navigate(item.path)}
              className="bg-white dark:bg-gray-800 text-gray-900 dark:text-white p-6 rounded-lg shadow-sm hover:shadow-md transition-all transform hover:-translate-y-1 border border-transparent hover:border-blue-500"
            >
              <div className="flex items-center space-x-4">
                <item.icon className="w-8 h-8" />
                <div className="text-left">
                  <h2 className="text-lg font-semibold">{item.title}</h2>
                  <p className="text-sm opacity-90">{item.description}</p>
                </div>
              </div>
            </button>
          ))}
        </div>
      </main>
    </div>
  );
};

export default AdminPanel;
