import React, { useState, useContext } from 'react';
import { ThemeContext } from '../../context/ThemeContext';
import { themes } from '../../theme/themes';

const TTSApp = ({ app }) => {
  const { isDark } = useContext(ThemeContext);
  const currentTheme = isDark ? themes.dark : themes.light;
  const [text, setText] = useState('');

  return (
    <div className="max-w-2xl mx-auto">
      <div className="mb-4">
        <label className="block text-sm font-medium mb-2">Enter text to speak</label>
        <textarea
          value={text}
          onChange={(e) => setText(e.target.value)}
          className={`w-full p-3 rounded-lg ${currentTheme.inputBg} border ${currentTheme.border} min-h-[100px]`}
          placeholder="Type something here..."
        />
      </div>
      <button
        className="px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition-colors"
      >
        Speak Text
      </button>
    </div>
  );
};

export default TTSApp;