// src/theme/themes.js
export const themes = {
    dark: {
      navBg: 'bg-gradient-to-b from-gray-800 to-gray-900',
      navBorder: 'border-gray-700',
      navShadow: 'shadow-md',
      bg: 'bg-gray-900',
      text: 'text-gray-100',
      secondaryBg: 'bg-gray-800/80',
      inputBg: 'bg-gray-800',
      border: 'border-gray-700',
      hover: 'hover:bg-gray-700 hover:border-gray-600',
      tagBg: 'bg-gray-700/80',
      tagText: 'text-gray-200',
      descriptionText: 'text-gray-300',
      repoText: 'text-gray-400',
      searchBg: 'bg-gray-800/90',
      searchIconColor: 'text-gray-400',
      searchPlaceholder: 'placeholder-gray-400',
      searchBorder: 'border-gray-600',
      searchFocus: 'focus:border-blue-500 focus:ring-2 focus:ring-blue-500/20',
      cardBg: 'bg-gray-800/50',
      cardHover: 'hover:bg-gray-800/80 hover:border-blue-500/50 hover:shadow-lg hover:shadow-blue-500/5',
      cardShadow: 'shadow-md shadow-black/10',
      activeTagBg: 'bg-blue-600',
      activeTagText: 'text-white',
      tagHover: 'hover:bg-gray-600',
      dropdownBg: 'bg-gray-800',
      iconHover: 'hover:bg-gray-700/80',
      buttonText: 'text-white',
      menuBorder: 'border-gray-700',
      menuShadow: 'shadow-xl shadow-black/20',
      menuHover: 'hover:bg-gray-700',
      menuText: 'text-gray-200',
      menuHeader: 'text-gray-200',
      divider: 'border-gray-700'
    },
    light: {
      navBg: 'bg-gradient-to-b from-white via-slate-50/80 to-slate-50',
      navBorder: 'border-slate-200',
      navShadow: 'shadow-sm shadow-blue-900/5',
      bg: 'bg-slate-50',
      text: 'text-slate-800',
      secondaryBg: 'bg-white/90',
      inputBg: 'bg-white',
      border: 'border-slate-200',
      hover: 'hover:bg-blue-50/50 hover:border-blue-200',
      tagBg: 'bg-blue-50',
      tagText: 'text-blue-700',
      descriptionText: 'text-slate-600',
      repoText: 'text-blue-600/80',
      searchBg: 'bg-white/80',
      searchIconColor: 'text-blue-500',
      searchPlaceholder: 'placeholder-slate-400',
      searchBorder: 'border-slate-200',
      searchFocus: 'focus:border-blue-400 focus:ring-2 focus:ring-blue-400/30',
      cardBg: 'bg-gradient-to-br from-white to-slate-50',
      cardHover: 'hover:bg-white hover:border-blue-200 hover:shadow-lg hover:shadow-blue-500/10',
      cardShadow: 'shadow-sm shadow-blue-900/5',
      activeTagBg: 'bg-blue-500',
      activeTagText: 'text-white',
      tagHover: 'hover:bg-blue-100',
      dropdownBg: 'bg-white/90',
      iconHover: 'hover:bg-blue-50',
      buttonText: 'text-slate-700',
      menuBorder: 'border-slate-200',
      menuShadow: 'shadow-xl shadow-blue-900/10',
      menuHover: 'hover:bg-blue-50',
      menuText: 'text-slate-600',
      menuHeader: 'text-slate-800',
      divider: 'border-slate-200/80'
    }
};