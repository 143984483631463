import React, { useState } from 'react';

const SolarSystem = () => {
  const [selectedPlanet, setSelectedPlanet] = useState(null);
  
  const planets = [
    { 
      name: 'Mercury', 
      radius: 8, 
      orbitRadius: 60, 
      color: '#A0522D',
      period: 5,
      fact: 'Closest planet to the Sun with extreme temperature variations'
    },
    { 
      name: 'Venus', 
      radius: 15, 
      orbitRadius: 100, 
      color: '#DEB887',
      period: 8,
      fact: 'Hottest planet due to greenhouse effect'
    },
    { 
      name: 'Earth', 
      radius: 16, 
      orbitRadius: 150, 
      color: '#4169E1',
      period: 10,
      fact: 'Only known planet with liquid water on surface'
    },
    { 
      name: 'Mars', 
      radius: 12, 
      orbitRadius: 200, 
      color: '#CD5C5C',
      period: 12,
      fact: 'Known as the Red Planet due to iron oxide'
    }
  ];

  const backgroundStars = Array(20).fill(null).map((_, i) => ({
    x: Math.random() * 600 - 300,
    y: Math.random() * 600 - 300,
    radius: Math.random() * 0.5 + 1
  }));

  return (
    <div className="w-full max-w-2xl mx-auto p-4">
      <svg 
        viewBox="-300 -300 600 600"
        className="w-full h-full bg-[#0B0B3B] rounded-lg shadow-lg"
      >
        <defs>
          <filter id="glow" x="-50%" y="-50%" width="200%" height="200%">
            <feGaussianBlur stdDeviation="5" result="coloredBlur"/>
            <feMerge>
              <feMergeNode in="coloredBlur"/>
              <feMergeNode in="SourceGraphic"/>
            </feMerge>
          </filter>
          <radialGradient id="starGradient">
            <stop offset="0%" stopColor="#fff" />
            <stop offset="100%" stopColor="#FFD700" />
          </radialGradient>
        </defs>

        {/* Background stars */}
        {backgroundStars.map((star, i) => (
          <circle
            key={i}
            cx={star.x}
            cy={star.y}
            r={star.radius}
            fill="white"
            className="opacity-75"
          />
        ))}

        {/* Central star */}
        <circle 
          cx="0" 
          cy="0" 
          r="30" 
          fill="url(#starGradient)"
          filter="url(#glow)"
          className="cursor-pointer"
          onClick={() => setSelectedPlanet(null)}
        />

        {/* Planets */}
        {planets.map((planet) => (
          <g key={planet.name}>
            {/* Orbit path */}
            <circle
              cx="0"
              cy="0"
              r={planet.orbitRadius}
              fill="none"
              stroke="white"
              strokeWidth="0.5"
              className="opacity-10"
            />
            
            {/* Planet */}
            <g>
              <animateTransform
                attributeName="transform"
                type="rotate"
                from="0 0 0"
                to="360 0 0"
                dur={`${planet.period}s`}
                repeatCount="indefinite"
              />
              <circle
                cx={planet.orbitRadius}
                cy="0"
                r={selectedPlanet === planet.name ? planet.radius * 1.2 : planet.radius}
                fill={planet.color}
                className="cursor-pointer transition-all duration-300"
                onClick={() => setSelectedPlanet(planet.name)}
              />
            </g>
          </g>
        ))}

        {/* Info display */}
        {selectedPlanet && (
          <g>
            <rect
              x="-290"
              y="-290"
              width="200"
              height="70"
              rx="10"
              fill="rgba(0,0,0,0.7)"
            />
            <text
              x="-280"
              y="-260"
              fill="white"
              className="text-sm"
            >
              {selectedPlanet}
            </text>
            <text
              x="-280"
              y="-240"
              fill="white"
              className="text-xs"
            >
              {planets.find(p => p.name === selectedPlanet)?.fact}
            </text>
          </g>
        )}
      </svg>

      <div className="mt-4 text-center text-gray-300 text-sm">
        Click on any planet to learn more about it
      </div>
    </div>
  );
};

export default SolarSystem;