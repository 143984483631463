import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:8000';

const api = axios.create({
  baseURL: API_URL,
  withCredentials: true
});

export const login = async (credentials) => {
  const formData = new FormData();
  formData.append('username', credentials.username);
  formData.append('password', credentials.password);
  
  const response = await api.post('/api/auth/login', formData);
  return response.data;
};

export const signup = async (userData) => {
  const response = await api.post('/api/auth/signup', userData);
  return response.data;
};

export const getCurrentUser = async () => {
  try {
    const response = await api.get('/api/auth/me');
    return response.data;
  } catch (error) {
    return null;
  }
};

export const checkAuth = async () => {
  try {
    await api.get('/api/auth/me');
    return true;
  } catch (error) {
    return false;
  }
};

export const logout = () => {
  document.cookie = 'access_token=; Max-Age=0; path=/; domain=' + window.location.hostname + '; samesite=lax';
};
