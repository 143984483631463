import React, { createContext, useState, useEffect } from 'react';

// Create the ThemeContext with default value
export const ThemeContext = createContext({
  isDark: true,
  toggleTheme: () => {},
});

// Create a ThemeProvider component
export const ThemeProvider = ({ children }) => {
  // Initialize theme state from localStorage or use system preference as fallback
  const [isDark, setIsDark] = useState(() => {
    const storedTheme = localStorage.getItem('isDark');
    if (storedTheme !== null) {
      return JSON.parse(storedTheme);
    }
    // Use system preference as fallback
    return window.matchMedia('(prefers-color-scheme: dark)').matches;
  });

  // Toggle theme and persist to localStorage
  const toggleTheme = () => {
    setIsDark((prev) => !prev);
  };

  useEffect(() => {
    localStorage.setItem('isDark', JSON.stringify(isDark));
    // Update the HTML `data-theme` or class for CSS
    if (isDark) {
      document.documentElement.classList.add('dark');
    } else {
      document.documentElement.classList.remove('dark');
    }
  }, [isDark]);

  return (
    <ThemeContext.Provider value={{ isDark, toggleTheme }}>
      {children}
    </ThemeContext.Provider>
  );
};
