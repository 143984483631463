import React, { useState, useEffect } from 'react';
import { Plus, ChevronLeft } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { getUsers, getAllApps, updateUserAppAccess, createUser, updateUser, deleteUser } from '../services/admin';
import UserList from '../components/admin/UserList';
import UserFormModal from '../components/admin/UserFormModal';
import { themes } from '../theme/themes';

const AdminUsersPage = () => {
  const navigate = useNavigate();
  const [users, setUsers] = useState([]);
  const [apps, setApps] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showCreateUserForm, setShowCreateUserForm] = useState(false);
  const [editingUser, setEditingUser] = useState(null);
  const [expandedUsers, setExpandedUsers] = useState(new Set());
  const [isDark] = useState(true);

  const [userFormData, setUserFormData] = useState({
    username: '',
    email: '',
    password: '',
    is_admin: false
  });

  const currentTheme = isDark ? themes.dark : themes.light;

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const [usersData, appsData] = await Promise.all([
        getUsers(),
        getAllApps()
      ]);
      setUsers(usersData);
      setApps(appsData);
    } catch (err) {
      setError('Failed to load data');
    } finally {
      setLoading(false);
    }
  };

  const handleAccessToggle = async (userId, appId, currentAccess) => {
    try {
      await updateUserAppAccess(userId, appId, !currentAccess);
      await fetchData();
    } catch (err) {
      setError('Failed to update access');
    }
  };

  const handleCreateUserSubmit = async (e) => {
    e.preventDefault();
    try {
      await createUser(userFormData);
      await fetchData();
      setShowCreateUserForm(false);
      setUserFormData({ username: '', email: '', password: '', is_admin: false });
    } catch (err) {
      setError('Failed to create user');
    }
  };

  const handleUpdateUserSubmit = async (e) => {
    e.preventDefault();
    try {
      const updateData = {
        username: userFormData.username,
        email: userFormData.email,
        password: userFormData.password,
        is_admin: userFormData.is_admin
      };
      await updateUser(editingUser.id, updateData);
      await fetchData();
      setEditingUser(null);
      setUserFormData({ username: '', email: '', password: '', is_admin: false });
    } catch (err) {
      setError('Failed to update user');
    }
  };

  const handleDeleteUser = async (userId) => {
    if (window.confirm('Are you sure you want to delete this user?')) {
      try {
        await deleteUser(userId);
        await fetchData();
      } catch (err) {
        setError('Failed to delete user');
      }
    }
  };

  const toggleUserExpanded = (userId) => {
    const newExpanded = new Set(expandedUsers);
    if (newExpanded.has(userId)) {
      newExpanded.delete(userId);
    } else {
      newExpanded.add(userId);
    }
    setExpandedUsers(newExpanded);
  };

  const startEditingUser = (user) => {
    setEditingUser(user);
    setUserFormData({
      username: user.username,
      email: user.email,
      password: '',
      is_admin: user.is_admin
    });
  };

  if (loading) return (
    <div className={`${currentTheme.bg} ${currentTheme.text} min-h-screen flex items-center justify-center`}>
      Loading...
    </div>
  );

  if (error) return (
    <div className={`${currentTheme.bg} ${currentTheme.text} min-h-screen flex items-center justify-center`}>
      {error}
    </div>
  );

  return (
    <div className={`${currentTheme.bg} ${currentTheme.text} min-h-screen`}>
      {/* Header */}
      <div className={`${currentTheme.secondaryBg} border-b ${currentTheme.border} sticky top-0 z-20 shadow-sm`}>
        <div className="max-w-5xl mx-auto px-6 py-4">
          <div className="flex items-center justify-between">
            <div className="flex items-center space-x-4">
              <button
                onClick={() => navigate('/admin')}
                className={`flex items-center space-x-2 px-3 py-2 rounded-lg ${currentTheme.hover} transition-colors`}
              >
                <ChevronLeft className="w-5 h-5" />
                <span>Back to Admin Panel</span>
              </button>
            </div>
            <button
              onClick={() => setShowCreateUserForm(true)}
              className="flex items-center space-x-2 px-4 py-2 bg-blue-500 hover:bg-blue-600 text-white rounded-lg transition-colors"
            >
              <Plus className="w-5 h-5" />
              <span>Create User</span>
            </button>
          </div>
        </div>
      </div>

      {/* Main Content */}
      <main className="max-w-5xl mx-auto px-6 py-8">
        <UserList
          users={users}
          apps={apps}
          expandedUsers={expandedUsers}
          currentTheme={currentTheme}
          onToggleExpand={toggleUserExpanded}
          onEditUser={startEditingUser}
          onDeleteUser={handleDeleteUser}
          onToggleAccess={handleAccessToggle}
        />
      </main>

      {/* Modal */}
      <UserFormModal
        isOpen={showCreateUserForm || editingUser !== null}
        onClose={() => {
          setShowCreateUserForm(false);
          setEditingUser(null);
          setUserFormData({ username: '', email: '', password: '', is_admin: false });
        }}
        onSubmit={editingUser ? handleUpdateUserSubmit : handleCreateUserSubmit}
        formData={userFormData}
        setFormData={setUserFormData}
        currentTheme={currentTheme}
        isEditing={editingUser !== null}
      />
    </div>
  );
};

export default AdminUsersPage;
