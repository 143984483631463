import React, { useState, useEffect, useContext } from 'react';
import { Plus, ChevronLeft } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { getAllApps, createApp, updateApp, deleteApp, toggleAppStatus } from '../services/admin';
import AppList from '../components/admin/AppList';
import AppFormModal from '../components/admin/AppFormModal';
import { ThemeContext } from '../context/ThemeContext'; // Import ThemeContext
import { themes } from '../theme/themes';

const AdminAppsPage = () => {
  const navigate = useNavigate();
  const { isDark } = useContext(ThemeContext); // Consume ThemeContext
  const currentTheme = isDark ? themes.dark : themes.light;

  const [apps, setApps] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showCreateAppForm, setShowCreateAppForm] = useState(false);
  const [editingApp, setEditingApp] = useState(null);
  const [appFormData, setAppFormData] = useState({
    name: '',
    slug: '',
    description: '',
    icon: '',
    repo: '',
    component_name: '',
    tags: [],
    disabled: false
  });

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const appsData = await getAllApps();
      setApps(appsData);
    } catch (err) {
      setError('Failed to load apps');
    } finally {
      setLoading(false);
    }
  };

  const handleCreateAppSubmit = async (e) => {
    e.preventDefault();
    try {
      await createApp(appFormData);
      await fetchData();
      setShowCreateAppForm(false);
      setAppFormData({
        name: '',
        slug: '',
        description: '',
        icon: '',
        repo: '',
        component_name: '',
        tags: [],
        disabled: false
      });
    } catch (err) {
      setError('Failed to create app');
    }
  };

  const handleUpdateAppSubmit = async (e) => {
    e.preventDefault();
    try {
      await updateApp(editingApp.id, appFormData);
      await fetchData();
      setEditingApp(null);
      setAppFormData({
        name: '',
        slug: '',
        description: '',
        icon: '',
        repo: '',
        component_name: '',
        tags: [],
        disabled: false
      });
    } catch (err) {
      setError('Failed to update app');
    }
  };

  const handleDeleteApp = async (appId) => {
    if (window.confirm('Are you sure you want to delete this app?')) {
      try {
        await deleteApp(appId);
        await fetchData();
      } catch (err) {
        setError('Failed to delete app');
      }
    }
  };

  const handleAppStatusToggle = async (appId, currentStatus) => {
    try {
      await toggleAppStatus(appId, !currentStatus);
      await fetchData();
    } catch (err) {
      setError('Failed to update app status');
    }
  };

  const startEditingApp = (app) => {
    setEditingApp(app);
    setAppFormData({
      name: app.name,
      slug: app.slug,
      description: app.description,
      icon: app.icon,
      repo: app.repo,
      component_name: app.component_name,
      tags: app.tags,
      disabled: app.disabled
    });
  };

  if (loading) return (
    <div className={`${currentTheme.bg} ${currentTheme.text} min-h-screen flex items-center justify-center`}>
      Loading...
    </div>
  );

  if (error) return (
    <div className={`${currentTheme.bg} ${currentTheme.text} min-h-screen flex items-center justify-center`}>
      {error}
    </div>
  );

  return (
    <div className={`${currentTheme.bg} ${currentTheme.text} min-h-screen`}>
      {/* Header */}
      <div className={`${currentTheme.secondaryBg} border-b ${currentTheme.border} sticky top-0 z-20 shadow-sm`}>
        <div className="max-w-5xl mx-auto px-6 py-4">
          <div className="flex items-center justify-between">
            <div className="flex items-center space-x-4">
              <button
                onClick={() => navigate('/admin')}
                className={`flex items-center space-x-2 px-3 py-2 rounded-lg ${currentTheme.hover} transition-colors`}
              >
                <ChevronLeft className="w-5 h-5" />
                <span>Back to Admin Panel</span>
              </button>
            </div>
            <button
              onClick={() => setShowCreateAppForm(true)}
              className="flex items-center space-x-2 px-4 py-2 bg-blue-500 hover:bg-blue-600 text-white rounded-lg transition-colors"
            >
              <Plus className="w-5 h-5" />
              <span>Create App</span>
            </button>
          </div>
        </div>
      </div>

      {/* Main Content */}
      <main className="max-w-5xl mx-auto px-6 py-8">
        <AppList
          apps={apps}
          currentTheme={currentTheme}
          onEditApp={startEditingApp}
          onDeleteApp={handleDeleteApp}
          onToggleStatus={handleAppStatusToggle}
        />
      </main>

      {/* Modal */}
      <AppFormModal
        isOpen={showCreateAppForm || editingApp !== null}
        onClose={() => {
          setShowCreateAppForm(false);
          setEditingApp(null);
          setAppFormData({
            name: '',
            slug: '',
            description: '',
            icon: '',
            repo: '',
            component_name: '',
            tags: [],
            disabled: false
          });
        }}
        onSubmit={editingApp ? handleUpdateAppSubmit : handleCreateAppSubmit}
        formData={appFormData}
        setFormData={setAppFormData}
        currentTheme={currentTheme}
        isEditing={editingApp !== null}
      />
    </div>
  );
};

export default AdminAppsPage;
