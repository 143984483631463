import React, { useState, useEffect, useMemo, useContext } from 'react';
import { Sun, Moon, Search, X, Filter, Settings, LogOut, HelpCircle, Users } from 'lucide-react';
import { getUserApps } from '../services/apps';
import { logout, getCurrentUser } from '../services/auth';
import { useNavigate, useParams } from 'react-router-dom';
import { ThemeContext } from '../context/ThemeContext';
import { themes } from '../theme/themes';
import AppContainer from '../components/apps/AppContainer';

const AppsPage = () => {
  const navigate = useNavigate();
  const { appSlug } = useParams();  // Get the app slug from URL params
  const [isUserMenuOpen, setIsUserMenuOpen] = useState(false);
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const { isDark, toggleTheme } = useContext(ThemeContext);
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedTags, setSelectedTags] = useState(new Set());
  const [apps, setApps] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentUser, setCurrentUser] = useState(null);

  const currentTheme = isDark ? themes.dark : themes.light;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [appsData, userData] = await Promise.all([
          getUserApps(),
          getCurrentUser()
        ]);
        setApps(appsData);
        setCurrentUser(userData);
        setError(null);
      } catch (err) {
        console.error('Error in fetchData:', err);
        setError(err.message || 'Failed to load data');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  // Find the currently active app based on the URL param
  const activeApp = useMemo(() => {
    return apps.find(app => app.slug === appSlug);
  }, [apps, appSlug]);

  // Get all unique tags
  const allTags = useMemo(() => {
    const tagSet = new Set();
    apps.forEach(app => {
      if (Array.isArray(app.tags)) {
        app.tags.forEach(tag => tagSet.add(tag));
      }
    });
    return Array.from(tagSet).sort();
  }, [apps]);

  const toggleTag = (tag) => {
    const newTags = new Set(selectedTags);
    if (newTags.has(tag)) {
      newTags.delete(tag);
    } else {
      newTags.add(tag);
    }
    setSelectedTags(newTags);
  };

  const handleLogout = () => {
    logout();
    navigate('/login');
  };

  const navigateToAdmin = () => {
    navigate('/admin');
    setIsUserMenuOpen(false);
  };

  const handleAppClick = (app) => {
    navigate(`/apps/${app.slug}`);
  };

  const filteredApps = useMemo(() => {
    const query = searchQuery.toLowerCase();
    return apps.filter(app => {
      const matchesSearch =
        app.name.toLowerCase().includes(query) ||
        app.description.toLowerCase().includes(query) ||
        app.repo.toLowerCase().includes(query) ||
        (Array.isArray(app.tags) && app.tags.some(tag => tag.toLowerCase().includes(query)));

      const matchesTags =
        selectedTags.size === 0 ||
        (Array.isArray(app.tags) && Array.from(selectedTags).every(tag => app.tags.includes(tag)));

      return matchesSearch && matchesTags;
    });
  }, [searchQuery, selectedTags, apps]);

  if (loading) {
    return (
      <div className={`${currentTheme.bg} ${currentTheme.text} min-h-screen flex items-center justify-center`}>
        <div className="animate-pulse">Loading...</div>
      </div>
    );
  }

  if (error) {
    return (
      <div className={`${currentTheme.bg} ${currentTheme.text} min-h-screen flex items-center justify-center`}>
        <div className="text-red-500">{error}</div>
      </div>
    );
  }

  return (
    <div className={`${currentTheme.bg} ${currentTheme.text} min-h-screen`}>
      {/* Header */}
      <div className={`${currentTheme.navBg} ${currentTheme.navBorder} border-b sticky top-0 z-20 ${currentTheme.navShadow} backdrop-blur-sm`}>
        <div className="w-full px-6 py-4 relative flex justify-center">
          {/* Search Container */}
          <div className="w-[600px] flex items-center space-x-2">
            <div className={`flex-1 flex items-center ${currentTheme.searchBg} px-4 py-2.5 rounded-lg border ${currentTheme.searchBorder} ${currentTheme.searchFocus} ${currentTheme.transition}`}>
              <Search className={`w-5 h-5 ${currentTheme.searchIconColor}`} />
              <input
                type="text"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                placeholder="Search apps..."
                className={`${currentTheme.searchBg} w-full focus:outline-none ${currentTheme.text} ${currentTheme.searchPlaceholder} ml-3 text-base ${currentTheme.transition}`}
              />
            </div>
            <div className="relative">
              <button
                onClick={() => setIsFilterOpen(!isFilterOpen)}
                className={`p-2.5 rounded-lg ${selectedTags.size > 0 ? currentTheme.activeTagBg : currentTheme.searchBg} 
                  border ${currentTheme.searchBorder} ${currentTheme.transition} relative ${currentTheme.searchFocus}`}
                aria-label="Filter by tags"
              >
                <Filter className={`w-5 h-5 ${selectedTags.size > 0 ? 'text-white' : currentTheme.searchIconColor}`} />
                {selectedTags.size > 0 && (
                  <span className="absolute -top-1 -right-1 bg-blue-500 text-white text-xs rounded-full w-4 h-4 flex items-center justify-center">
                    {selectedTags.size}
                  </span>
                )}
              </button>

              {/* Tag Filter Dropdown */}
              {isFilterOpen && (
                <>
                  {/* Backdrop for closing filter dropdown */}
                  <div
                    className="fixed inset-0 z-30"
                    onClick={() => setIsFilterOpen(false)}
                  />
                  <div className={`absolute right-0 mt-2 w-72 ${currentTheme.dropdownBg} border ${currentTheme.menuBorder} 
                    rounded-lg ${currentTheme.menuShadow} z-40 p-3 backdrop-blur-sm ${currentTheme.transition}`}>
                    <div className="flex items-center justify-between mb-2">
                      <h3 className="text-sm font-medium">Filter by tags</h3>
                      {selectedTags.size > 0 && (
                        <button
                          onClick={() => setSelectedTags(new Set())}
                          className={`text-xs ${currentTheme.menuText} hover:text-blue-500 ${currentTheme.transition}`}
                        >
                          Clear all
                        </button>
                      )}
                    </div>
                    <div className="flex flex-wrap gap-1.5">
                      {allTags.map((tag) => {
                        const isSelected = selectedTags.has(tag);
                        return (
                          <button
                            key={tag}
                            onClick={() => toggleTag(tag)}
                            className={`
                              px-2 py-1 rounded text-xs font-medium ${currentTheme.transition}
                              ${isSelected
                                ? `${currentTheme.activeTagBg} ${currentTheme.activeTagText}`
                                : `${currentTheme.tagBg} ${currentTheme.tagText} ${currentTheme.tagHover}`}
                            `}
                          >
                            {tag}
                            {isSelected && <X className="w-3 h-3 ml-1 inline-block" />}
                          </button>
                        );
                      })}
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>

          {/* Right side controls */}
          <div className="absolute right-6 flex items-center space-x-3">
            <button
              onClick={toggleTheme}
              className={`p-2.5 rounded-lg ${currentTheme.searchBg} border ${currentTheme.searchBorder} ${currentTheme.transition} ${currentTheme.searchFocus}`}
              aria-label="Toggle theme"
            >
              {isDark ?
                <Sun className={`w-5 h-5 ${currentTheme.buttonText}`} /> :
                <Moon className={`w-5 h-5 ${currentTheme.buttonText}`} />
              }
            </button>

            {/* User Profile Menu */}
            <div className="relative">
              <button
                onClick={() => setIsUserMenuOpen(!isUserMenuOpen)}
                className={`flex items-center space-x-2 p-1.5 rounded-lg ${currentTheme.searchBg} ${currentTheme.transition} border ${currentTheme.searchBorder} ${currentTheme.searchFocus}`}
              >
                <div className="w-7 h-7 rounded-full bg-gradient-to-br from-blue-400 to-blue-600 flex items-center justify-center text-white text-sm font-medium">
                  {currentUser?.username?.charAt(0).toUpperCase() || 'U'}
                </div>
              </button>

              {/* User Dropdown Menu */}
              {isUserMenuOpen && (
                <>
                  {/* Backdrop for closing dropdown */}
                  <div
                    className="fixed inset-0 z-30"
                    onClick={() => setIsUserMenuOpen(false)}
                  />

                  {/* Dropdown Content */}
                  <div
                    className={`
                      absolute right-0 mt-2 w-56 rounded-lg border ${currentTheme.menuBorder} 
                      ${currentTheme.dropdownBg} ${currentTheme.menuShadow} ${currentTheme.transition}
                      transform origin-top-right z-40 overflow-hidden backdrop-blur-sm
                    `}
                  >
                    {/* User Info Section */}
                    <div className={`p-3 border-b ${currentTheme.divider}`}>
                      <div className="flex items-center space-x-3">
                        <div className="w-10 h-10 rounded-full bg-gradient-to-br from-blue-400 to-blue-600 flex items-center justify-center text-white font-medium">
                          {currentUser?.username?.charAt(0).toUpperCase() || 'U'}
                        </div>
                        <div>
                          <div className={`font-medium ${currentTheme.menuHeader}`}>
                            {currentUser?.username}
                          </div>
                          <div className={`text-sm ${currentTheme.menuText}`}>
                            {currentUser?.email}
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* Menu Items */}
                    <div className="py-1">
                      {currentUser?.is_admin && (
                        <button
                          onClick={navigateToAdmin}
                          className={`w-full px-4 py-2 text-left text-sm ${currentTheme.menuText} ${currentTheme.menuHover} ${currentTheme.transition} flex items-center space-x-2`}
                        >
                          <Users className="w-4 h-4" />
                          <span>Admin Panel</span>
                        </button>
                      )}
                      <button
                        className={`w-full px-4 py-2 text-left text-sm ${currentTheme.menuText} ${currentTheme.menuHover} ${currentTheme.transition} flex items-center space-x-2`}
                      >
                        <Settings className="w-4 h-4" />
                        <span>Settings</span>
                      </button>
                      <button
                        className={`w-full px-4 py-2 text-left text-sm ${currentTheme.menuText} ${currentTheme.menuHover} ${currentTheme.transition} flex items-center space-x-2`}
                      >
                        <HelpCircle className="w-4 h-4" />
                        <span>Help & Support</span>
                      </button>
                      <button
                        onClick={handleLogout}
                        className={`w-full px-4 py-2 text-left text-sm ${currentTheme.menuText} ${currentTheme.menuHover} ${currentTheme.transition} flex items-center space-x-2`}
                      >
                        <LogOut className="w-4 h-4" />
                        <span>Sign out</span>
                      </button>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* Main Content */}
      <main className="max-w-5xl mx-auto px-6 py-8">
        {/* Show AppContainer if an app is active */}
        {activeApp ? (
          <AppContainer app={activeApp} />
        ) : (
          // Show app list if no app is active
          <>
            {filteredApps.length === 0 ? (
              <div className="text-center py-16">
                <div className="text-4xl mb-4">🔍</div>
                <div className="text-xl font-semibold mb-2">No apps found</div>
                <div className={currentTheme.descriptionText}>Try adjusting your search terms or filters</div>
              </div>
            ) : (
              <div className="space-y-4">
                {filteredApps.map((app, index) => (
                  <div
                    key={index}
                    onClick={() => handleAppClick(app)}
                    className={`${currentTheme.cardBg} p-5 rounded-lg border ${currentTheme.border} ${currentTheme.cardHover} 
                    ${currentTheme.transition} cursor-pointer group ${currentTheme.cardShadow}`}
                  >
                    <div className="flex items-start space-x-4">
                      <div className={`w-12 h-12 ${currentTheme.searchBg} rounded-lg flex items-center justify-center text-2xl 
                        ${currentTheme.transition} group-hover:scale-105`}>
                        {app.icon}
                      </div>
                      <div className="min-w-0 flex-1">
                        <div className="flex items-center space-x-3 mb-1">
                          <h3 className="font-medium text-lg">{app.name}</h3>
                          <span className={`text-sm ${currentTheme.repoText} truncate`}>{app.repo}</span>
                        </div>
                        <p className={`text-sm ${currentTheme.descriptionText} mb-3 line-clamp-2`}>
                          {app.description}
                        </p>
                        <div className="flex flex-wrap gap-1.5">
                          {Array.isArray(app.tags) && app.tags.map((tag, i) => (
                            <button
                              key={i}
                              onClick={(e) => {
                                e.stopPropagation();
                                toggleTag(tag);
                              }}
                              className={`
                                px-2 py-0.5 rounded text-xs font-medium ${currentTheme.transition}
                                ${selectedTags.has(tag)
                                  ? `${currentTheme.activeTagBg} ${currentTheme.activeTagText}`
                                  : `${currentTheme.tagBg} ${currentTheme.tagText} hover:scale-105`}
                              `}
                            >
                              {tag}
                            </button>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </>
        )}
      </main>
    </div>
  );
};

export default AppsPage;
