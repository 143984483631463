import React from 'react';
import { Edit2, Trash2 } from 'lucide-react';

const AppList = ({ 
  apps, 
  currentTheme,
  onEditApp,
  onDeleteApp,
  onToggleStatus
}) => {
  return (
    <div className="mb-8">
      {/* Removed h2 element */}
      <div className="space-y-4">
        {apps.map(app => (
          <div
            key={app.id}
            className={`${currentTheme.secondaryBg} rounded-lg border ${currentTheme.border} p-4 transition-shadow duration-200 shadow-sm hover:shadow-md`}
          >
            <div className="flex items-center justify-between">
              <div className="flex items-center space-x-4">
                <div className={`w-10 h-10 ${currentTheme.inputBg} rounded-lg flex items-center justify-center text-2xl`}>
                  {app.icon}
                </div>
                <div>
                  <h3 className="font-medium">{app.name}</h3>
                  <p className={`text-sm ${currentTheme.descriptionText}`}>{app.description}</p>
                  <div className="flex flex-wrap gap-2 mt-2">
                    {app.tags.map((tag, index) => (
                      <span
                        key={index}
                        className={`px-2 py-0.5 rounded text-xs ${currentTheme.tagBg} ${currentTheme.tagText}`}
                      >
                        {tag}
                      </span>
                    ))}
                  </div>
                </div>
              </div>
              <div className="flex items-center space-x-2">
                <button
                  onClick={() => onToggleStatus(app.id, app.disabled)}
                  className={`px-3 py-1 rounded-full text-sm font-medium transition-colors ${
                    app.disabled
                      ? 'bg-gray-100 text-gray-800 hover:bg-gray-200'
                      : 'bg-green-100 text-green-800 hover:bg-green-200'
                  }`}
                >
                  {app.disabled ? 'Disabled' : 'Enabled'}
                </button>
                <button
                  onClick={() => onEditApp(app)}
                  className="p-2 text-gray-400 hover:text-gray-600 rounded-lg transition-colors"
                >
                  <Edit2 className="w-5 h-5" />
                </button>
                <button
                  onClick={() => onDeleteApp(app.id)}
                  className="p-2 text-red-400 hover:text-red-600 rounded-lg transition-colors"
                >
                  <Trash2 className="w-5 h-5" />
                </button>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default AppList;
