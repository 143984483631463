import React from 'react';

const AppFormModal = ({
  isOpen,
  onClose,
  onSubmit,
  formData,
  setFormData,
  currentTheme,
  isEditing
}) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className={`${currentTheme.secondaryBg} rounded-lg p-6 w-full max-w-md`}>
        <h2 className="text-xl font-bold mb-4">{isEditing ? 'Edit App' : 'Create New App'}</h2>
        <form onSubmit={onSubmit}>
          <div className="space-y-4">
            <div>
              <label className="block text-sm font-medium mb-1">Name</label>
              <input
                type="text"
                value={formData.name}
                onChange={(e) => setFormData({...formData, name: e.target.value})}
                className={`w-full p-2 rounded-lg ${currentTheme.inputBg} border ${currentTheme.border}`}
                required
              />
            </div>
            {!isEditing && (
              <div>
                <label className="block text-sm font-medium mb-1">Slug</label>
                <input
                  type="text"
                  value={formData.slug}
                  onChange={(e) => setFormData({...formData, slug: e.target.value})}
                  className={`w-full p-2 rounded-lg ${currentTheme.inputBg} border ${currentTheme.border}`}
                  required
                />
              </div>
            )}
            <div>
              <label className="block text-sm font-medium mb-1">Description</label>
              <textarea
                value={formData.description}
                onChange={(e) => setFormData({...formData, description: e.target.value})}
                className={`w-full p-2 rounded-lg ${currentTheme.inputBg} border ${currentTheme.border}`}
                required
              />
            </div>
            <div>
              <label className="block text-sm font-medium mb-1">Icon</label>
              <input
                type="text"
                value={formData.icon}
                onChange={(e) => setFormData({...formData, icon: e.target.value})}
                className={`w-full p-2 rounded-lg ${currentTheme.inputBg} border ${currentTheme.border}`}
                required
              />
            </div>
            <div>
              <label className="block text-sm font-medium mb-1">Repository</label>
              <input
                type="text"
                value={formData.repo}
                onChange={(e) => setFormData({...formData, repo: e.target.value})}
                className={`w-full p-2 rounded-lg ${currentTheme.inputBg} border ${currentTheme.border}`}
                required
              />
            </div>
            <div>
              <label className="block text-sm font-medium mb-1">Component Name</label>
              <input
                type="text"
                value={formData.component_name}
                onChange={(e) => setFormData({...formData, component_name: e.target.value})}
                className={`w-full p-2 rounded-lg ${currentTheme.inputBg} border ${currentTheme.border}`}
                required
              />
            </div>
            <div>
              <label className="block text-sm font-medium mb-1">Tags (comma-separated)</label>
              <input
                type="text"
                value={Array.isArray(formData.tags) ? formData.tags.join(',') : formData.tags}
                onChange={(e) => setFormData({...formData, tags: e.target.value.split(',').map(tag => tag.trim())})}
                className={`w-full p-2 rounded-lg ${currentTheme.inputBg} border ${currentTheme.border}`}
              />
            </div>
            <div>
              <label className="flex items-center">
                <input
                  type="checkbox"
                  checked={formData.disabled}
                  onChange={(e) => setFormData({...formData, disabled: e.target.checked})}
                  className="mr-2"
                />
                <span className="text-sm font-medium">Disabled</span>
              </label>
            </div>
          </div>
          <div className="flex justify-end gap-2 mt-6">
            <button
              type="button"
              onClick={onClose}
              className={`px-4 py-2 rounded-lg ${currentTheme.hover}`}
            >
              Cancel
            </button>
            <button
              type="submit"
              className="px-4 py-2 bg-green-500 text-white rounded-lg hover:bg-green-600 transition-colors"
            >
              {isEditing ? 'Update' : 'Create'}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AppFormModal;
