import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import LoginPage from './pages/LoginPage';
import AppsPage from './pages/AppsPage';
import AdminPanel from './pages/AdminPanel';
import AdminAppsPage from './pages/AdminAppsPage';
import AdminUsersPage from './pages/AdminUsersPage';
import PrivateRoute from './components/auth/PrivateRoute';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/login" element={<LoginPage />} />
        <Route
          path="/apps"
          element={
            <PrivateRoute>
              <AppsPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/apps/:appSlug"
          element={
            <PrivateRoute>
              <AppsPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/admin"
          element={
            <PrivateRoute>
              <AdminPanel />
            </PrivateRoute>
          }
        />
        <Route
          path="/admin/apps"
          element={
            <PrivateRoute>
              <AdminAppsPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/admin/users"
          element={
            <PrivateRoute>
              <AdminUsersPage />
            </PrivateRoute>
          }
        />
        <Route path="/" element={<Navigate to="/apps" replace />} />
      </Routes>
    </Router>
  );
}

export default App;