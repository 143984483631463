import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:8000';

const api = axios.create({
  baseURL: API_URL,
  withCredentials: true
});

export const getUsers = async () => {
  const response = await api.get('/api/admin/users');
  return response.data;
};

export const getAllApps = async () => {
  const response = await api.get('/api/admin/apps');
  return response.data;
};

export const updateUserAppAccess = async (userId, appId, hasAccess) => {
  const response = await api.post('/api/admin/user-access', {
    user_id: userId,
    app_id: appId,
    has_access: hasAccess
  });
  return response.data;
};

export const createUser = async (userData) => {
  const response = await api.post('/api/admin/users', userData);
  return response.data;
};

export const updateUser = async (userId, userData) => {
  const response = await api.put(`/api/admin/users/${userId}`, userData);
  return response.data;
};

export const deleteUser = async (userId) => {
  const response = await api.delete(`/api/admin/users/${userId}`);
  return response.data;
};

// App CRUD operations
export const createApp = async (appData) => {
  const response = await api.post('/api/admin/apps', appData);
  return response.data;
};

export const updateApp = async (appId, appData) => {
  const response = await api.put(`/api/admin/apps/${appId}`, appData);
  return response.data;
};

export const deleteApp = async (appId) => {
  const response = await api.delete(`/api/admin/apps/${appId}`);
  return response.data;
};

export const toggleAppStatus = async (appId, disabled) => {
  const response = await api.put(`/api/admin/apps/${appId}/status`, { disabled });
  return response.data;
};
