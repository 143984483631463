import React from 'react';
import { Edit2, Trash2, ChevronUp, ChevronDown } from 'lucide-react';

const UserList = ({ 
  users, 
  apps, 
  expandedUsers, 
  currentTheme,
  onToggleExpand,
  onEditUser,
  onDeleteUser,
  onToggleAccess
}) => {
  return (
    <div>
      <h2 className="text-xl font-semibold mb-4">Users</h2>
      <div className="space-y-4">
        {users.map(user => (
          <div
            key={user.id}
            className={`${currentTheme.secondaryBg} rounded-lg border ${currentTheme.border} overflow-hidden transition-shadow duration-200 shadow-sm hover:shadow-md`}
          >
            {/* User Header */}
            <div className="p-4 flex items-center justify-between">
              <div className="flex items-center space-x-4">
                <div className="w-10 h-10 rounded-full bg-gradient-to-br from-blue-400 to-blue-600 flex items-center justify-center text-white font-medium">
                  {user.username.charAt(0).toUpperCase()}
                </div>
                <div>
                  <h3 className="font-medium">{user.username}</h3>
                  <p className={`text-sm ${currentTheme.descriptionText}`}>{user.email}</p>
                </div>
                <span className={`px-2 py-1 rounded-full text-xs font-medium ${
                  user.is_admin ? 'bg-purple-100 text-purple-800' : 'bg-gray-100 text-gray-800'
                }`}>
                  {user.is_admin ? 'Admin' : 'User'}
                </span>
              </div>
              <div className="flex items-center space-x-2">
                <button
                  onClick={() => onEditUser(user)}
                  className="p-2 text-gray-400 hover:text-gray-600 rounded-lg transition-colors"
                >
                  <Edit2 className="w-5 h-5" />
                </button>
                <button
                  onClick={() => onDeleteUser(user.id)}
                  className="p-2 text-red-400 hover:text-red-600 rounded-lg transition-colors"
                >
                  <Trash2 className="w-5 h-5" />
                </button>
                <button
                  onClick={() => onToggleExpand(user.id)}
                  className="p-2 text-gray-400 hover:text-gray-600 rounded-lg transition-colors"
                >
                  {expandedUsers.has(user.id) ? (
                    <ChevronUp className="w-5 h-5" />
                  ) : (
                    <ChevronDown className="w-5 h-5" />
                  )}
                </button>
              </div>
            </div>

            {/* Apps Access Section */}
            {expandedUsers.has(user.id) && (
              <div className={`p-4 border-t ${currentTheme.border}`}>
                <h4 className="text-sm font-medium mb-3">App Access</h4>
                <div className="space-y-2">
                  {apps.map(app => {
                    const hasAccess = user.apps.some(userApp => userApp.id === app.id);
                    return (
                      <div
                        key={app.id}
                        className={`flex items-center justify-between p-3 rounded-lg ${currentTheme.inputBg}`}
                      >
                        <div className="flex items-center space-x-3">
                          <div className={`w-8 h-8 ${currentTheme.secondaryBg} rounded-lg flex items-center justify-center text-lg`}>
                            {app.icon}
                          </div>
                          <span>{app.name}</span>
                        </div>
                        <button
                          onClick={() => onToggleAccess(user.id, app.id, hasAccess)}
                          className={`px-3 py-1 rounded-full text-sm font-medium transition-colors ${
                            hasAccess
                              ? 'bg-green-100 text-green-800 hover:bg-green-200'
                              : 'bg-gray-100 text-gray-800 hover:bg-gray-200'
                          }`}
                        >
                          {hasAccess ? 'Enabled' : 'Disabled'}
                        </button>
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default UserList;
